export const countries = [
  { code: 'AF', label: 'countries.af' },
  { code: 'AX', label: 'countries.ax' },
  { code: 'AL', label: 'countries.al' },
  { code: 'DZ', label: 'countries.dz' },
  { code: 'AS', label: 'countries.as' },
  { code: 'AD', label: 'countries.ad' },
  { code: 'AO', label: 'countries.ao' },
  { code: 'AI', label: 'countries.ai' },
  { code: 'AQ', label: 'countries.aq' },
  { code: 'AG', label: 'countries.ag' },
  { code: 'AR', label: 'countries.ar' },
  { code: 'AM', label: 'countries.am' },
  { code: 'AW', label: 'countries.aw' },
  { code: 'AU', label: 'countries.au' },
  { code: 'AT', label: 'countries.at' },
  { code: 'AZ', label: 'countries.az' },
  { code: 'BS', label: 'countries.bs' },
  { code: 'BH', label: 'countries.bh' },
  { code: 'BD', label: 'countries.bd' },
  { code: 'BB', label: 'countries.bb' },
  { code: 'BY', label: 'countries.by' },
  { code: 'BE', label: 'countries.be' },
  { code: 'BZ', label: 'countries.bz' },
  { code: 'BJ', label: 'countries.bj' },
  { code: 'BM', label: 'countries.bm' },
  { code: 'BT', label: 'countries.bt' },
  { code: 'BO', label: 'countries.bo' },
  { code: 'BQ', label: 'countries.bq' },
  { code: 'BA', label: 'countries.ba' },
  { code: 'BW', label: 'countries.bw' },
  { code: 'BV', label: 'countries.bv' },
  { code: 'BR', label: 'countries.br' },
  { code: 'IO', label: 'countries.io' },
  { code: 'BN', label: 'countries.bn' },
  { code: 'BG', label: 'countries.bg' },
  { code: 'BF', label: 'countries.bf' },
  { code: 'BI', label: 'countries.bi' },
  { code: 'CV', label: 'countries.cv' },
  { code: 'KH', label: 'countries.kh' },
  { code: 'CM', label: 'countries.cm' },
  { code: 'CA', label: 'countries.ca' },
  { code: 'KY', label: 'countries.ky' },
  { code: 'CF', label: 'countries.cf' },
  { code: 'TD', label: 'countries.td' },
  { code: 'CL', label: 'countries.cl' },
  { code: 'CN', label: 'countries.cn' },
  { code: 'CX', label: 'countries.cx' },
  { code: 'CC', label: 'countries.cc' },
  { code: 'CO', label: 'countries.co' },
  { code: 'KM', label: 'countries.km' },
  { code: 'CG', label: 'countries.cg' },
  { code: 'CD', label: 'countries.cd' },
  { code: 'CK', label: 'countries.ck' },
  { code: 'CR', label: 'countries.cr' },
  { code: 'HR', label: 'countries.hr' },
  { code: 'CU', label: 'countries.cu' },
  { code: 'CW', label: 'countries.cw' },
  { code: 'CY', label: 'countries.cy' },
  { code: 'CZ', label: 'countries.cz' },
  { code: 'DK', label: 'countries.dk' },
  { code: 'DJ', label: 'countries.dj' },
  { code: 'DM', label: 'countries.dm' },
  { code: 'DO', label: 'countries.do' },
  { code: 'EC', label: 'countries.ec' },
  { code: 'EG', label: 'countries.eg' },
  { code: 'SV', label: 'countries.sv' },
  { code: 'GQ', label: 'countries.gq' },
  { code: 'ER', label: 'countries.er' },
  { code: 'EE', label: 'countries.ee' },
  { code: 'SZ', label: 'countries.sz' },
  { code: 'ET', label: 'countries.et' },
  { code: 'FK', label: 'countries.fk' },
  { code: 'FO', label: 'countries.fo' },
  { code: 'FJ', label: 'countries.fj' },
  { code: 'FI', label: 'countries.fi' },
  { code: 'FR', label: 'countries.fr' },
  { code: 'GF', label: 'countries.gf' },
  { code: 'PF', label: 'countries.pf' },
  { code: 'TF', label: 'countries.tf' },
  { code: 'GA', label: 'countries.ga' },
  { code: 'GM', label: 'countries.gm' },
  { code: 'GE', label: 'countries.ge' },
  { code: 'DE', label: 'countries.de' },
  { code: 'GH', label: 'countries.gh' },
  { code: 'GI', label: 'countries.gi' },
  { code: 'GR', label: 'countries.gr' },
  { code: 'GL', label: 'countries.gl' },
  { code: 'GT', label: 'countries.gt' },
  { code: 'GG', label: 'countries.gg' },
  { code: 'GN', label: 'countries.gn' },
  { code: 'GP', label: 'countries.gp' },
  { code: 'GT', label: 'countries.gt' },
  { code: 'GW', label: 'countries.gw' },
  { code: 'GY', label: 'countries.gy' },
  { code: 'HT', label: 'countries.ht' },
  { code: 'HM', label: 'countries.hm' },
  { code: 'VA', label: 'countries.va' },
  { code: 'HN', label: 'countries.hn' },
  { code: 'HK', label: 'countries.hk' },
  { code: 'HU', label: 'countries.hu' },
  { code: 'IS', label: 'countries.is' },
  { code: 'IN', label: 'countries.in' },
  { code: 'ID', label: 'countries.id' },
  { code: 'IR', label: 'countries.ir' },
  { code: 'IQ', label: 'countries.iq' },
  { code: 'IE', label: 'countries.ie' },
  { code: 'IM', label: 'countries.im' },
  { code: 'IL', label: 'countries.il' },
  { code: 'IT', label: 'countries.it' },
  { code: 'JM', label: 'countries.jm' },
  { code: 'JP', label: 'countries.jp' },
  { code: 'JE', label: 'countries.je' },
  { code: 'JO', label: 'countries.jo' },
  { code: 'KZ', label: 'countries.kz' },
  { code: 'KE', label: 'countries.ke' },
  { code: 'KI', label: 'countries.ki' },
  { code: 'KP', label: 'countries.kp' },
  { code: 'KR', label: 'countries.kr' },
  { code: 'KS', label: 'countries.ks' },
  { code: 'KW', label: 'countries.kw' },
  { code: 'KG', label: 'countries.kg' },
  { code: 'LA', label: 'countries.la' },
  { code: 'LV', label: 'countries.lv' },
  { code: 'LB', label: 'countries.lb' },
  { code: 'LS', label: 'countries.ls' },
  { code: 'LR', label: 'countries.lr' },
  { code: 'LY', label: 'countries.ly' },
  { code: 'LI', label: 'countries.li' },
  { code: 'LT', label: 'countries.lt' },
  { code: 'LU', label: 'countries.lu' },
  { code: 'MO', label: 'countries.mo' },
  { code: 'MG', label: 'countries.mg' },
  { code: 'MW', label: 'countries.mw' },
  { code: 'MY', label: 'countries.my' },
  { code: 'MV', label: 'countries.mv' },
  { code: 'ML', label: 'countries.ml' },
  { code: 'MT', label: 'countries.mt' },
  { code: 'MH', label: 'countries.mh' },
  { code: 'MQ', label: 'countries.mq' },
  { code: 'MR', label: 'countries.mr' },
  { code: 'MU', label: 'countries.mu' },
  { code: 'YT', label: 'countries.yt' },
  { code: 'MX', label: 'countries.mx' },
  { code: 'FM', label: 'countries.fm' },
  { code: 'MD', label: 'countries.md' },
  { code: 'MC', label: 'countries.mc' },
  { code: 'MN', label: 'countries.mn' },
  { code: 'ME', label: 'countries.me' },
  { code: 'MS', label: 'countries.ms' },
  { code: 'MA', label: 'countries.ma' },
  { code: 'MZ', label: 'countries.mz' },
  { code: 'MM', label: 'countries.mm' },
  { code: 'NA', label: 'countries.na' },
  { code: 'NC', label: 'countries.nc' },
  { code: 'NZ', label: 'countries.nz' },
  { code: 'NI', label: 'countries.ni' },
  { code: 'NE', label: 'countries.ne' },
  { code: 'NG', label: 'countries.ng' },
  { code: 'NU', label: 'countries.nu' },
  { code: 'NF', label: 'countries.nf' },
  { code: 'MP', label: 'countries.mp' },
  { code: 'NO', label: 'countries.no' },
  { code: 'OM', label: 'countries.om' },
  { code: 'PK', label: 'countries.pk' },
  { code: 'PW', label: 'countries.pw' },
  { code: 'PS', label: 'countries.ps' },
  { code: 'PA', label: 'countries.pa' },
  { code: 'PG', label: 'countries.pg' },
  { code: 'PY', label: 'countries.py' },
  { code: 'PE', label: 'countries.pe' },
  { code: 'PH', label: 'countries.ph' },
  { code: 'PN', label: 'countries.pn' },
  { code: 'PL', label: 'countries.pl' },
  { code: 'PT', label: 'countries.pt' },
  { code: 'PR', label: 'countries.pr' },
  { code: 'QA', label: 'countries.qa' },
  { code: 'RE', label: 'countries.re' },
  { code: 'RO', label: 'countries.ro' },
  { code: 'RU', label: 'countries.ru' },
  { code: 'RW', label: 'countries.rw' },
  { code: 'BL', label: 'countries.bl' },
  { code: 'SH', label: 'countries.sh' },
  { code: 'KN', label: 'countries.kn' },
  { code: 'LC', label: 'countries.lc' },
  { code: 'MF', label: 'countries.mf' },
  { code: 'PM', label: 'countries.pm' },
  { code: 'VC', label: 'countries.vc' },
  { code: 'WS', label: 'countries.ws' },
  { code: 'SM', label: 'countries.sm' },
  { code: 'ST', label: 'countries.st' },
  { code: 'SA', label: 'countries.sa' },
  { code: 'SN', label: 'countries.sn' },
  { code: 'RS', label: 'countries.rs' },
  { code: 'SC', label: 'countries.sc' },
  { code: 'SL', label: 'countries.sl' },
  { code: 'SG', label: 'countries.sg' },
  { code: 'SX', label: 'countries.sx' },
  { code: 'SK', label: 'countries.sk' },
  { code: 'SI', label: 'countries.si' },
  { code: 'SB', label: 'countries.sb' },
  { code: 'SO', label: 'countries.so' },
  { code: 'ZA', label: 'countries.za' },
  { code: 'GS', label: 'countries.gs' },
  { code: 'SS', label: 'countries.ss' },
  { code: 'ES', label: 'countries.es' },
  { code: 'LK', label: 'countries.lk' },
  { code: 'SD', label: 'countries.sd' },
  { code: 'SR', label: 'countries.sr' },
  { code: 'SJ', label: 'countries.sj' },
  { code: 'SZ', label: 'countries.sz' },
  { code: 'SE', label: 'countries.se' },
  { code: 'CH', label: 'countries.ch' },
  { code: 'SY', label: 'countries.sy' },
  { code: 'TW', label: 'countries.tw' },
  { code: 'TJ', label: 'countries.tj' },
  { code: 'TZ', label: 'countries.tz' },
  { code: 'TH', label: 'countries.th' },
  { code: 'TL', label: 'countries.tl' },
  { code: 'TG', label: 'countries.tg' },
  { code: 'TK', label: 'countries.tk' },
  { code: 'TO', label: 'countries.to' },
  { code: 'TT', label: 'countries.tt' },
  { code: 'TN', label: 'countries.tn' },
  { code: 'TR', label: 'countries.tr' },
  { code: 'TM', label: 'countries.tm' },
  { code: 'TC', label: 'countries.tc' },
  { code: 'TV', label: 'countries.tv' },
  { code: 'UG', label: 'countries.ug' },
  { code: 'UA', label: 'countries.ua' },
  { code: 'AE', label: 'countries.ae' },
  { code: 'GB', label: 'countries.gb' },
  { code: 'US', label: 'countries.us' },
  { code: 'UM', label: 'countries.um' },
  { code: 'UY', label: 'countries.uy' },
  { code: 'UZ', label: 'countries.uz' },
  { code: 'VU', label: 'countries.vu' },
  { code: 'VE', label: 'countries.ve' },
  { code: 'VN', label: 'countries.vn' },
  { code: 'WF', label: 'countries.wf' },
  { code: 'EH', label: 'countries.eh' },
  { code: 'YE', label: 'countries.ye' },
  { code: 'ZM', label: 'countries.zm' },
  { code: 'ZW', label: 'countries.zw' },
];
